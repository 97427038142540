import React from "react";
import { graphql, Link } from "gatsby";
import { format, parseISO } from "date-fns";
import { GatsbyImage } from "gatsby-plugin-image";
import PatternContainer from "../components/pattern-container";
import { ReactComponent as BagelBuzzLogo } from "../images/blog-logo.svg";
import Button from "../components/button";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { PAGE_SIZE } from "../utils/constants";
import classNames from "classnames";

export default function BlogIndex({ data, pageContext }) {
  const { blogPosts } = data;
  const { totalCount } = blogPosts;
  const { page } = pageContext;

  return (
    <Layout>
      <SEO
        title={"Bagel Bytes"}
        description={data.prismicBlogPage.data.seo_description}
        keywords={data.prismicBlogPage.data.seo_keywords
          ?.split(",")
          .map(keyword => keyword.trim())}
      />
      <PatternContainer className="px-3 py-24 flex flex-col items-center">
        <BagelBuzzLogo className="hidden md:block fill-current text-rosens-marzipan" />
        <BagelBuzzLogo
          className="md:hidden fill-current text-rosens-marzipan"
          width="250"
          height="59.93"
          viewBox="0 0 351.17 84.18"
        />
        <section className="max-w-5xl flex flex-col items-center w-full mt-24">
          {blogPosts.nodes.map(blogPost => (
            <Link
              to={`/blog/${blogPost.uid}`}
              className="flex flex-wrap w-full mt-8"
            >
              <div className="w-full md:w-1/2">
                {blogPost.data.thumbnail &&
                blogPost.data.thumbnail.gatsbyImageData ? (
                  <GatsbyImage
                    className="w-full h-full"
                    image={blogPost.data.thumbnail.gatsbyImageData}
                  />
                ) : (
                  <div className="bg-gray-100 w-full h-full" />
                )}
              </div>
              <div className="w-full bg-white text-rosens-green-kelp px-8 py-24 md:w-1/2 md:flex md:flex-col md:justify-center">
                {blogPost.data.date_posted && (
                  <div className="text-rosens-dove-gray mb-4 text-sm">
                    {format(parseISO(blogPost.data.date_posted), "MMMM d, y")}
                  </div>
                )}
                <h3 className="mb-4 text-left">{blogPost.data.title}</h3>
                <p className="mb-4">{blogPost.data.blurb}</p>
                <div>
                  <Button
                    variant="white"
                    size="small"
                    className="border-2 border-rosens-green-kelp py-2 px-4"
                    to={`/blog/${blogPost.uid}`}
                  >
                    {blogPost.data.call_to_action || "Read More"}
                  </Button>
                </div>
              </div>
            </Link>
          ))}
          <BlogFooter page={page} totalCount={totalCount} />
        </section>
      </PatternContainer>
    </Layout>
  );
}

function BlogFooter({ page, totalCount }) {
  const pageNumberClassName =
    "px-2 background-transparent font-proxima-nova font-semibold border-2 border-white mx-1";
  const pageCount = Math.ceil(totalCount / PAGE_SIZE);
  return (
    <footer className="self-end mt-8 flex items-center">
      {page > 5 && (
        <>
          <Link to="/blog" className={pageNumberClassName}>
            1
          </Link>
          <span>⋯</span>
        </>
      )}
      {page - 2 > 0 && (
        <Link
          to={getBlogIndexPageUrl(page - 2)}
          className={pageNumberClassName}
        >
          {page - 2}
        </Link>
      )}
      {page - 1 > 0 && (
        <Link
          to={getBlogIndexPageUrl(page - 1)}
          className={pageNumberClassName}
        >
          {page - 1}
        </Link>
      )}
      <Link
        to={getBlogIndexPageUrl(page)}
        className={classNames(
          pageNumberClassName,
          "bg-white",
          "text-rosens-green-kelp"
        )}
      >
        {page}
      </Link>
      {page + 1 <= pageCount && (
        <Link
          to={getBlogIndexPageUrl(page + 1)}
          className={pageNumberClassName}
        >
          {page + 1}
        </Link>
      )}
      {page + 2 <= pageCount && (
        <Link
          to={getBlogIndexPageUrl(page + 2)}
          className={pageNumberClassName}
        >
          {page + 2}
        </Link>
      )}
      {page < pageCount - 3 && (
        <>
          <span>⋯</span>
          <Link
            to={getBlogIndexPageUrl(pageCount)}
            className={pageNumberClassName}
          >
            {pageCount}
          </Link>
        </>
      )}
      {page > 1 && (
        <Link
          className="font-proxima-nova font-semibold tracking-wide uppercase mx-2"
          to={getBlogIndexPageUrl(page - 1)}
        >
          Prev
        </Link>
      )}
      {page < pageCount && (
        <Link
          className="font-proxima-nova font-semibold tracking-wide uppercase mx-3"
          to={getBlogIndexPageUrl(page + 1)}
        >
          Next
        </Link>
      )}
    </footer>
  );
}
export const query = graphql`
  query GetBlogPosts($limit: Int!, $skip: Int!) {
    prismicBlogPage {
      data {
        seo_description
        seo_keywords
      }
    }
    blogPosts: allPrismicBlogPost(
      limit: $limit
      skip: $skip
      sort: { fields: data___date_posted, order: DESC }
    ) {
      totalCount
      nodes {
        uid
        data {
          title
          blurb
          call_to_action
          date_posted
          thumbnail {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 4160
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`;

function getBlogIndexPageUrl(page) {
  if (page === 1) {
    return "/blog";
  } else {
    return `/blog/page/${page}`;
  }
}
